export function getTranslatedString(text: string): string {
	const translations: any = {
		administration: 'Administration',
		'administrative support': 'Soutien administratif',
		'communications, business development and strategy':
			'Communication, développement commercial et stratégie',
		drivers: 'Chauffeurs',
		'engineering and construction': 'Ingénierie et construction',
		'finance and accounting': 'Finances et comptabilité',
		'human resources': 'Ressources humaines',
		'legal affairs and compliance': 'Affaires juridiques et conformité',
		'maintenance and cleaning': 'Maintenance et entretien',
		'operations management': 'Gestion des opérations',
		'performance, digital and safety': 'Performance, digitale et sécurité',
		'full-time': 'Temps plein',
		'part-time': 'Temps partiel',
		temporary: 'Temporaire'
	};

	return translations[text.trim().toLowerCase()] || text;
}
